// 合规管理
const writManage = () => import('@/webapp/service/wirt.vue')
const contractCompare = () => import('@/webapp/service/contract-compare.vue')
const writServiceTemplate = () => import('@/webapp/service/template.vue')
const orgaTemplate = () => import('@/webapp/service/orga-template.vue')
const contractPackage = () => import('@/webapp/service/contract-package.vue')
const lib = () => import('@/webapp/service/lib.vue')
const assistant = () => import('@/webapp/service/assistant.vue')
const contractReview = () => import('@/webapp/compliance/contract-review.vue')
const contractHonour = () => import('@/webapp/compliance/contract-honour.vue')
const contractArchive = () => import('@/webapp/compliance/contract-archive.vue')
const contractSign = () => import('@/webapp/compliance/contract-sign.vue')
const overview = () => import('@/webapp/compliance/overview.vue')
const contractEvidence = () => import('@/webapp/compliance/contract-evidence.vue')
const sealAudit = () => import('@/webapp/compliance/seal-audit.vue')
const Api = require('../apis/path')

export default [
  {
    path: '/compliance-manage',
    meta: {
      id: '045a6d63-94e3-4ff4-a743-df1203ae25e9',
      icon: '',
      name: 'compliance-manage',
      title: '合同合规', // person 合同管理, orga 合同合规
      titleExt: ['合同管理', '合同合规'],
      type: [0],
      apis: []
    },
    children: [
      {
        path: '/overview',
        meta: {
          id: '503b7bb6-0ac9-4b94-90c1-e192e109867f',
          icon: 'icon-zonglanicon2',
          name: 'overview',
          title: '总览',
          iconColor: '#507bfd',
          type: [2],
          apis: []
        },
        component: overview
      },
      {
        path: '/compliance',
        meta: {
          id: '01bc7a48-caa9-4e76-8b02-c8ea723d393e',
          icon: 'icon-hetongheguiicon',
          name: 'compliance',
          title: '合同合规',
          iconColor: '#507bfd',
          type: [0],
          apis: []
        },
        children: [
          {
            path: '/writ-manage',
            meta: {
              id: '2e3aefa4-53f4-4738-bf94-648684d8d669',
              icon: '',
              name: 'writ-manage',
              title: '合同起草·审查',
              type: [0]
            },
            component: writManage
          },
          {
            path: '/contract-review',
            meta: {
              id: '84cd1204-5db3-41d2-a797-e348cec5a74e',
              icon: '',
              name: 'contract-review',
              title: '合同审批',
              badge: 'contractReviewCount',
              type: [2],
              actions: [
                {
                  id: 'f780d7e6-8107-46ba-a512-0751567136ab',
                  name: 'flow',
                  title: '添加/编辑流程',
                  apis: []
                },
                {
                  id: '65bf225a-a5b0-4765-b68f-6fb4a6bb7dea',
                  name: 'all',
                  title: '所有审批',
                  apis: []
                }
              ]
            },
            component: contractReview
          },
          {
            path: '/contract-sign',
            meta: {
              id: '',
              icon: '',
              name: 'contract-sign',
              title: '合同签署',
              type: [1],
              badge: 'contractSignCount',
              actions: []
            },
            component: contractSign
          },
          {
            path: '/contract-sign',
            meta: {
              id: '91076a16-9bc5-4aed-9dd0-b2aeba027502',
              icon: '',
              name: 'contract-sign',
              title: '合同签署',
              type: [2],
              badge: 'contractSignCount',
              actions: [
                {
                  id: 'bd84a1cc-f116-46e9-94cd-f4a738d457d3',
                  name: 'open',
                  title: '电子签章管理',
                  apis: []
                },
                {
                  id: '8863f20c-902f-4a09-b4c6-38ccd3fa02ed',
                  name: 'new',
                  title: '签署按钮',
                  apis: []
                },
                {
                  id: '44bd8c0a-71cf-4ffd-9251-949460c0ec26',
                  name: 'all',
                  title: '所有签署',
                  apis: []
                },
                {
                  id: 'b17feb05-cfa9-4027-85f6-3feadfe7dc6c',
                  name: 'begin',
                  title: '发起任务',
                  apis: []
                }
              ]
            },
            component: contractSign
          },
          {
            path: '/seal-audit',
            meta: {
              id: '66e1215b-062c-4105-a807-8ce499dc022e',
              icon: '',
              name: 'seal-audit',
              title: '用印审批',
              badge: 'sealReviewCount',
              type: [2],
              actions: [
                {
                  id: 'd8649f02-8ead-457c-b240-3053b0e81098',
                  name: 'edit-flow',
                  title: '添加/编辑流程',
                  apis: []
                },
                {
                  id: '2fd52f9d-9444-491f-87e5-8563b87ed593',
                  name: 'all',
                  title: '所有审批',
                  apis: []
                }
              ]
            },
            component: sealAudit
          },
          {
            path: '/contract-honour',
            meta: {
              id: 'efa5ded3-9326-472e-a4b8-776be765af00',
              icon: '',
              name: 'contract-honour',
              title: '合同履约',
              type: [2],
              apis: [
                'post:/knowledge/v1/user/contract/performance',
                'put:/knowledge/v1/user/contract/performance/effective/{id}',
                'get:/knowledge/v1/user/contract/performance/page/list',
                'post:/knowledge/v1/user/contract/performance/plan',
                'post:/knowledge/v1/user/contract/performance/plan/copy/{id}',
                'get:/knowledge/v1/user/contract/performance/plan/page/list',
                'get:/knowledge/v1/user/contract/performance/plan/{id}',
                'put:/knowledge/v1/user/contract/performance/plan/{id}',
                'delete:/knowledge/v1/user/contract/performance/plan/{id}',
                'post:/knowledge/v1/user/contract/performance/voucher',
                'get:/knowledge/v1/user/contract/performance/{id}',
                'put:/knowledge/v1/user/contract/performance/{id}',
                'delete:/knowledge/v1/user/contract/performance/{id}',
                'get:/knowledge/v1/user/contract/performance/voucher/page/list',
                'get:/knowledge/v1/user/contract/performance/info/{id}',
                'get:/knowledge/v1/user/contract/performance/plan/log/page/list',
                'put:/knowledge/v1/user/contract/performance/person/{id}',
                'put:/knowledge/v1/user/contract/performance/person/{id}',
                'post:/knowledge/v1/user/contract/performance/msg',
                'get:/knowledge/v1/user/contract/performance/msg/detail',
                'put:/knowledge/v1/user/contract/performance/file/{id}',
                'put:/knowledge/v1/user/contract/performance/move/{id}',
                'put:/knowledge/v1/user/contract/performance/category',
                'get:/knowledge/v1/user/contract/performance/category',
                'get:/knowledge/v1/user/contract/performance/plan/msg/{id}',
                'put:/knowledge/v1/user/contract/performance/plan/sort/{id}'
              ],
              actions: [
                {
                  id: '7262c78e-6872-4f07-abad-be2f18581bdc',
                  name: 'manage',
                  title: '履约管理',
                  apis: []
                },
                {
                  id: '031b6604-4774-432b-9c04-a5abcb3af9a2',
                  name: 'remind',
                  title: '提醒设置',
                  apis: []
                },
                {
                  id: '02b5cd9a-0af6-40c9-a84d-a31b4794fdea',
                  name: 'category',
                  title: '修改分类',
                  apis: []
                }
              ]
            },
            component: contractHonour
          },
          {
            path: '/contract-archive',
            meta: {
              id: 'e6e2efb9-a62b-4e64-b460-f81a8114ef9f',
              icon: '',
              name: 'contract-archive',
              title: '合同归档',
              type: [2],
              apis: [
                'post:/knowledge/v1/user/contract/performance',
                'put:/knowledge/v1/user/contract/performance/effective/{id}',
                'get:/knowledge/v1/user/contract/performance/page/list',
                'post:/knowledge/v1/user/contract/performance/plan',
                'post:/knowledge/v1/user/contract/performance/plan/copy/{id}',
                'get:/knowledge/v1/user/contract/performance/plan/page/list',
                'get:/knowledge/v1/user/contract/performance/plan/{id}',
                'put:/knowledge/v1/user/contract/performance/plan/{id}',
                'delete:/knowledge/v1/user/contract/performance/plan/{id}',
                'post:/knowledge/v1/user/contract/performance/voucher',
                'get:/knowledge/v1/user/contract/performance/{id}',
                'put:/knowledge/v1/user/contract/performance/{id}',
                'delete:/knowledge/v1/user/contract/performance/{id}',
                'get:/knowledge/v1/user/contract/performance/voucher/page/list',
                'get:/knowledge/v1/user/contract/performance/info/{id}',
                'get:/knowledge/v1/user/contract/performance/plan/log/page/list',
                'put:/knowledge/v1/user/contract/performance/person/{id}',
                'put:/knowledge/v1/user/contract/performance/person/{id}',
                'post:/knowledge/v1/user/contract/performance/msg',
                'get:/knowledge/v1/user/contract/performance/msg/detail',
                'put:/knowledge/v1/user/contract/performance/file/{id}',
                'put:/knowledge/v1/user/contract/performance/move/{id}',
                'put:/knowledge/v1/user/contract/performance/category',
                'get:/knowledge/v1/user/contract/performance/category',
                'get:/knowledge/v1/user/contract/performance/plan/msg/{id}',
                'put:/knowledge/v1/user/contract/performance/plan/sort/{id}'
              ]
            },
            component: contractArchive
          },
          {
            path: '/contract-compare',
            meta: {
              id: '3dd369b3-6ef1-41a5-b4f5-281611780080',
              icon: '',
              name: 'contract-compare',
              title: '合同比对',
              type: [0]
            },
            component: contractCompare
          },
          {
            path: '/contract-evidence',
            meta: {
              id: '2de8e8ec-847e-4cb1-8766-5867c315cad5',
              icon: '',
              name: 'contract-evidence',
              title: '证据管理',
              type: [2]
            },
            component: contractEvidence
          },
          {
            path: '/contract-evidence',
            meta: {
              id: '',
              icon: '',
              name: 'contract-evidence',
              title: '证据管理',
              type: [1]
            },
            component: contractEvidence
          }
        ]
      },
      {
        path: '/writ',
        meta: {
          id: '0b17a69a-3c28-470f-9208-1889dbb1e804',
          icon: 'icon-fawufuwuicon',
          iconColor: '#45acf6',
          name: 'writ',
          title: '合同模板库',
          type: [0]
        },
        children: [
          {
            path: '/ftwk',
            meta: {
              id: '86be2b0d-3b4e-4821-b979-454b100d4aeb',
              icon: '',
              name: 'ftwk',
              title: '合同模板',
              type: [0]
            },
            component: lib
          },
          {
            path: '/contract-pacakge',
            meta: {
              id: '26cc0195-78b7-4481-87fd-09fba8a3e08f',
              icon: '',
              name: 'contract-pacakge',
              title: '行业合同包',
              type: [0]
            },
            component: contractPackage
          },
          {
            path: '/template',
            meta: {
              id: 'c7ab0e7b-e108-47f1-909b-86ead652ef97',
              icon: '',
              name: 'template',
              title: '已购模板',
              type: [0]
            },
            component: writServiceTemplate
          },
          {
            path: '/orga-template',
            meta: {
              id: '1c6f39a7-199f-457a-8a25-8bfac9cf2647',
              icon: '',
              name: 'orga-template',
              title: '企业模板',
              type: [2],
              apis: [
                `get:${Api.path.knowledge}/agreement/company/page/list`, // 列表
                `post:${Api.path.knowledge}/agreement/company`, // 添加
                `delete:${Api.path.knowledge}/agreement/company/{id}` // 删除
              ],
              actions: [
                {
                  id: '679f1230-eaf1-42d8-9418-0f55f5a3f90f',
                  name: 'add',
                  title: '上传模板',
                  apis: []
                },
                {
                  id: 'b92a7ba8-3786-49bd-9025-b77f27c3e7fa',
                  name: 'view',
                  title: '查看企业文件',
                  apis: []
                },
                {
                  id: '40932ce1-19b1-494a-9851-abb298015edb',
                  name: 'view',
                  title: '查看签署模板',
                  apis: []
                }
              ]
            },
            component: orgaTemplate
          }
        ]
      },
      {
        path: '/assistant',
        meta: {
          id: '',
          icon: 'icon-gengduozhushoufuwuicon',
          iconColor: '#30d327',
          name: 'assistant',
          title: '更多助手服务',
          type: [0]
        },
        component: assistant
      }

    ]
  }
]
